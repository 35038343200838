import * as Constants from '../constants/Constants';

export class StorageHelper {

    static setStripeClientSecret (clientSecret) {
        localStorage.setItem(Constants.CLIENT_SECRET_STRIPE_KEY, clientSecret);
    }

    static getStripeClientSecret () {
        return localStorage.getItem(Constants.CLIENT_SECRET_STRIPE_KEY);
    }

    static removeStripeClientSecret () {
        return localStorage.removeItem(Constants.CLIENT_SECRET_STRIPE_KEY);
    }
}