export class StringHelper {

    /**
     * Replace all param with value, the object must be passed with this structure {[param1: value1, param2: value2 ... ]}
     */
    static composeUrlParam(url, params) {
        params.forEach(function(param) {
            url = url.replace(param.key, param.value);
        });
        return url;
    }
}

