import React from "react";
import {Container, Header, Segment} from "semantic-ui-react";

/**
 * Component displayed when the page not found in the main router
 */
export class NotFoundPage extends React.Component {
    render() {
        return (
            <Container style={{width: "330px"}}>
                <Segment  textAlign="center"  style={{margin: "10px"}}>
                    <Header as='h2' style={{margin: "5px"}} color={"blue"}>404</Header>
                    <Header as='h2' style={{margin: "10px"}} >Page non trovata</Header>
                    <p style={{margin: "10px"}}>Hai selezionato un elemento che non esiste</p>
                </Segment>
            </Container>

        );
    }
}