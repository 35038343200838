import React from 'react';
import "./Parking.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {withRouter} from "react-router-dom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";

class Parking extends React.Component {

	render() {
		return (
			<div id="Parking">
				<React.Fragment>	
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2021</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Elenco Parcheggi</h1>
										
										<div class="parking-item">
											<h3>1. Parcheggio Piazza degli Ulivi, Porto Cervo</h3>
											<div class="alert alert-info">
												La sosta è consentita per le sole autovetture per un massimo di 2 ore.
											</div>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1318568,9.5349022/@41.1316067,9.5343985,321m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										
										<div class="parking-item">
											<h3>2. Parcheggio Rotatoria Porto Cervo</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1306931,9.5353689/@41.1316067,9.5343985,321m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>3. Parcheggio Autobus Porto Cervo</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1289176,9.5360888/@41.1304104,9.5349332,476m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>4. Parcheggio Petra Manna</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.0816665,9.5486576/@41.0816073,9.5485574,40m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>5. Parcheggio La Celvia</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.0828241,9.5474646/@41.08285,9.4776727,20546m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>6. Parcheggio Capriccioli</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//Parking/@41.0796951,9.4804771,20547m/data=!3m2!1e3!4b1!4m9!4m8!1m0!1m5!1m1!1s0x12d938795518f947:0x38bf303f3c0b5630!2m2!1d9.5505172!2d41.0797163!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>7. Parcheggio Piccolo Pevero</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.113599,9.5390749/@41.1136861,9.4691662,20536m/data=!3m2!1e3!4b1!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>8. Parcheggio Poltu Quatu</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1364929,9.4995176/@41.1364388,9.4991648,99m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>9. Parcheggio Baia Sardinia Centro</h3>
											<div class="alert alert-info">
												La sosta è consentita per le sole autovetture per un massimo di 2 ore.
											</div>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1392002,9.4785482/@41.138926,9.4780822,356m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>10. Parcheggio Porto Sole</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1324649,9.4761307/@41.1327588,9.4746913,821m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>11. Parcheggio Aquadream</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1309063,9.4788485/@41.1309942,9.4779117,417m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>12. Parcheggio Tre Monti</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//Parcheggio+Spiaggia+Tre+Monti/@41.1385697,9.3953102,20528m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x0:0xbdd71180e0e8030f!2m2!1d9.4653503!2d41.1385909" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>13. Parcheggio Lu Nibbaru</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1138443,9.4573845/@41.1140943,9.3874286,20536m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>14. Parcheggio Tanca Manna</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.1333285,9.4331425/@41.1344037,9.3628527,20530m/data=!3m2!1e3!4b1!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>15. Parcheggio N° 1 Mannena</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//Parcheggio+Comunale+Mannena/@41.1383389,9.426411,1283m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x12d9438ba3a937f3:0xdb657ba866a0f2af!2m2!1d9.4306556!2d41.1382094!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>16. Parcheggio N° 2 Mannena Barca Bruciata</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//41.145217,9.4263501/@41.1451002,9.3564724,20526m/data=!3m1!1e3!4m2!4m1!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>17. Parcheggio N° 3 Mannena “L’Ulticeddu”</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//Area+di+parcheggio+La+Iaciola/@41.1529972,9.3567444,20524m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x12d943965cd056e1:0xf92f8c34e724467c!2m2!1d9.4267845!2d41.1530184!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
										<div class="parking-item">
											<h3>18. Parcheggio Centro servizi Capriccioli</h3>
											<a class="btn btn-default" rel="noopener noreferrer" href="https://www.google.it/maps/dir//Parking/@41.0796951,9.4804771,20547m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x12d938795518f947:0x38bf303f3c0b5630!2m2!1d9.5505172!2d41.0797163!3e0" target="_blank">
												<i class="car icon"></i>
												Indicazioni Stradali
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(Parking);
