import React from "react";
import {ElementsConsumer} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

export class InjectedCheckoutForm extends React.Component {

    render() {

        return (
            <ElementsConsumer>
                {({stripe, elements, payment_client_secret, price_amount, price_currency, paymentConfirmed}) => (
                    <CheckoutForm  stripe={stripe} elements={elements}
                                   payment_client_secret={payment_client_secret}
                                   price_amount={price_amount}
                                   price_currency={price_currency}
                    />
                )}
            </ElementsConsumer>
        );
    }
}

