import React from 'react';
import './Header.scss';
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";
import {withRouter} from "react-router-dom";
import logo_comune from '../../assets/images/logo_comune.png';
import logo_geseco from '../../assets/images/logo_geseco.png';


class HeaderCustom extends React.Component {
	render() {
		return (

			<div class="header">
				<div class="top-menu">
					<div class="container">
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-6 col-md-push-6">
								<ul class="list-inline">
									<li><img alt="logo geseco" class="partner-logo" src={logo_geseco} /></li>
									<li><img alt="logo comune" class="partner-logo" src={logo_comune} /></li>
								</ul>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-6 col-md-pull-6">
								<p class="h2 pull-left">
									<a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>
										Parcheggi Arzachena
									</a>
								</p>
								<div class="pull-right visible-xs visible-sm">
									<a href="#" class="btn btn-default" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
										<span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span> Menu
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="navbar-expand-md visible-xs visible-sm">
					<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
						<ul class="nav navbar-nav">
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2021</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
							<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
						</ul>
					</div>
				</div>
			</div>

		);
	}
}

export default withRouter(HeaderCustom)
