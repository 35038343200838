import React from 'react';
import "./Rates.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {withRouter} from "react-router-dom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";

class Rates extends React.Component {

	render() {
		return (
			<div id="rates">
				<React.Fragment>	
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2021</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Tariffe Abbonamenti</h1>
										<p>Residenti €15,00 stagionale;</p>
										<p>Non residenti con regolare contratto di lavoro, o titolari di un’attività nell’ambito del Comune di Arzachena, €60,00 mensili, valido solo nei centri urbani di Porto Cervo e Baia Sardinia;</p>
										<p>Non residenti proprietari di immobili e/o ospiti di alberghi, €25,00 settimanale, €60,00 mensile, €120,00 stagionale;</p>
										<p>Residenti nei comuni limitrofi senza sbocco a mare €60,00 stagionale;</p>
										
										<h1>Tariffe Orarie</h1>
										<p>La tariffa oraria non frazionabile per tutti i parcheggi è stabilita in:</p>
										<p>€1,50 per ora o frazione di ora successiva per autovettura con tariffa massima giornaliera di €7,50. Oltre le 5 ore non è dovuto alcun supplemento;</p>
										<p>€0,50 per ora o frazione di ora successiva per i motocicli con tariffa massima giornaliera di €2,50. Oltre le 5 ore non è dovuto alcun supplemento;</p>
										<p>€5,00 per ora per gli autobus.</p>
										<p class="margin-bottom-40">Nei parcheggi di Porto Cervo centro, piazza degli Ulivi e Baia Sardinia la sosta è consentita per le sole autovetture per un massimo di 2 ore, con possibilità del pagamento frazionato di 1 ora in €0,50 per i primi 30 minuti.</p>
										<a class="btn btn-primary btn-lg btn-block" href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(Rates);
