import {
    BASE_URL_API, URL_API_PARKING_PASS_REQUEST, URL_API_REPRINT_PASS, URL_REQUEST_LICENSE_PLATE_PARAM,
} from "../constants/Constants";
import axios from 'axios';
import {StringHelper} from "../constants/StringHelper";
import {formatDate} from "../utils";
import {PARKING_PASS_TYPE1, PARKING_PASS_TYPE1_EXT} from "../constants/Key";

const client = axios.create({
  baseURL: BASE_URL_API,
  responseType: 'json',
});

export const postParkingPassRequest = (first_name, last_name, identity_doc_type, identity_doc_number, identity_doc_released_by,
                                        identity_doc_date_released, identity_doc_date_expiration, email, phone, license_plate, parking_pass_type,
                                        identity_doc_front_media, identity_doc_back_media, vehicle_registration_media,
                                       certificate_media, nationality, residence_place, residence_address, residence_address_number, cf, activation_date,
                                       vehicle_registration_media_back) => {

    let released_date = formatDate(identity_doc_date_released);
    let expiration_date = formatDate(identity_doc_date_expiration);
    parking_pass_type = parking_pass_type === PARKING_PASS_TYPE1_EXT ? PARKING_PASS_TYPE1 : parking_pass_type;

    const formData = new FormData();
    console.log(first_name);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('identity_doc_type', identity_doc_type);
    formData.append('identity_doc_number', identity_doc_number);
    formData.append('identity_doc_released_by', identity_doc_released_by);
    formData.append('identity_doc_date_released', released_date);
    formData.append('identity_doc_date_expiration', expiration_date);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('license_plate', license_plate);
    formData.append('parking_pass_type', parking_pass_type);
    formData.append('identity_doc_front_media', identity_doc_front_media);
    formData.append('identity_doc_back_media', identity_doc_back_media);
    formData.append('vehicle_registration_media', vehicle_registration_media);
    formData.append('vehicle_registration_media_back', vehicle_registration_media_back);

    certificate_media !== null && formData.append('certificate_media', certificate_media);
    formData.append('nationality', nationality);
    cf !== "" && formData.append('cf', cf);
    formData.append('residence_place', residence_place);
    formData.append('residence_address', residence_address);
    activation_date !== "" && formData.append('activation_date', formatDate(activation_date));
    formData.append('residence_address_number', residence_address_number);
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    return client
        .post(URL_API_PARKING_PASS_REQUEST, formData, config)
        .then(res => {
            console.log("posted parking pass req", res.data);
            return res.data;
        }).catch(err =>  {
            console.log(err.message);
            console.error(err);
            throw err;
        });
};

export const reprintPass =  (license_plate, payment_method, last_4_digit) => {
    const params = {
        license_plate,
        payment_method,
        last_4_digit,
    };
    let urlReprintPass= StringHelper.composeUrlParam(URL_API_REPRINT_PASS, [{key: URL_REQUEST_LICENSE_PLATE_PARAM, value: license_plate}]);
    return client
        .post(urlReprintPass, params)
        .then(res => {
            console.log(res)
            return res.data;
        }).catch(err =>  {
            console.error(err);
            throw err;
        });
};
