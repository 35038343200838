import React from 'react';
import "./Home.scss";
import "semantic-ui-css/semantic.min.css";
import HeaderCustom from "../Common/HeaderCustom";
import {FooterCustom} from "../Common/FooterCustom";
import {
	RELATIVE_CONTACT_PATH,
	RELATIVE_HOME_PATH,
	RELATIVE_PRINT_PASS_PATH,
	RELATIVE_RATES_PATH,
	RELATIVE_RESERVATION_PATH,
	RELATIVE_FAQ_PATH,
	RELATIVE_PARKING_PATH
} from "../../constants/Constants";
import {withRouter} from "react-router-dom";

class Home extends React.Component {
	state = {redirect: false};

	performReservation() {
		this.props.history.push("/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH);
	}

	render() {
		return (
			<div id="home">
				<React.Fragment>	
					<HeaderCustom/>
					<div class="bg-f6f6f3">
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-md-3 hidden-xs hidden-sm">
									<div class="menu-left">
										<div class="menu-title">Acquista Abbonamento Parcheggio</div>
										<ul>
											<li class="active"><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_HOME_PATH}>Home</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Tariffe 2021</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_FAQ_PATH}>Domande Frequenti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PRINT_PASS_PATH}>Ristampa Tagliando</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_CONTACT_PATH}>Contatti</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_PARKING_PATH}>Elenco Parcheggi</a></li>
											<li><a href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a></li>
										</ul>
									</div>
								</div>
								<div class="col-xs-12 col-md-9">
									<div class="home-howto">
										<h1>Acquista Abbonamento Parcheggio</h1>
										<p class="home-cta">Gli abbonamenti per i parcheggi del Comune di Arzachena possono essere acquistati online. Acquista un abbonamento in 5 semplici passaggi:</p>
										<p><h2>Passo 1</h2>Clicca su Acquista Abbonamento e seleziona il tipo di tariffa.</p>
										<p><h2>Passo 2</h2>Inserisci il tuo nome e cognome, i tuoi contatti e i dati di un documento di identità a scelta tra carta di identità e passaporto. Carica una copia del tuo documento in corso di validità.</p>
										<p><h2>Passo 3</h2>Inserisci la targa del tuo veicolo e carica una copia della carta di circolazione. Carica altri documenti richiesti. Assicurati che i documenti siano tutti in corso di validità.</p>
										<p><h2>Passo 4</h2>Inserisci le informazioni della tua carta di credito. Accettiamo carte di credito VISA, Master Card e American Express. Sono inoltre accettate carte di debito e carte ricaricabili. (I pagamenti su questo sito sono sicuri)</p>
										<p class="margin-bottom-40"><h2>Passo 5</h2>Invia la richiesta e riceverai il tuo abbonamento via email entro 24 ore.</p>
										<a class="btn btn-primary btn-lg btn-block" href={"/" + this.props.match.params.locale + "/" + RELATIVE_RESERVATION_PATH}>Acquista Abbonamento</a>
									</div>
									<div class="home-rates">
										<div class="row">
											<div class="col-xs-12 col-md-6">
												<h3>Residenti</h3>
												<p>I residenti del Comune di Arzachena hanno diritto ad una tariffa forfettaria stagionale pari a euro 15.</p>
											</div>
											<div class="clearfix padding-40 visible-xs"></div>
											<div class="col-xs-12 col-md-6">
												<h3>Lavoratori non residenti</h3>
												<p>Non residenti con regolare contratto di lavoro, o titolari di un’attività nell’ambito del Comune di Arzachena.</p>
											</div>
											<div class="clearfix padding-40"></div>
											<div class="col-xs-12">
												<a class="" href={"/" + this.props.match.params.locale + "/" + RELATIVE_RATES_PATH}>Mostra tutte le tariffe</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterCustom/>
				</React.Fragment>
			</div>
		);
	}
}

export default withRouter(Home);
